<template>
  <div v-if="toastStore.visible" class="toast" :class="toastClass">
    <div class="toast-content space-x-2">
      <strong>{{ toastStore.title }}</strong>
      <p>{{ toastStore.message }}</p>
      <button v-if="toastStore.showClose" @click="toastStore.clearToast">X</button>
    </div>
  </div>
</template>

<script>
import { useToastStore } from '/entrypoints/stores/useToastStore';
import { computed } from 'vue';

export default {
  setup() {
    const toastStore = useToastStore();

    // Compute the toast class based on type
    const toastClass = computed(() => `toast--${toastStore.type}`);

    return {
      toastStore,  // Bind the whole store to ensure reactivity
      toastClass,  // Use a computed property for the class
    };
  },
};
</script>

<style scoped>
.toast {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.toast--info {
  background-color: #d9edf7;
  color: #31708f;
}

.toast--success {
  background-color: #dff0d8;
  color: #3c763d;
}

.toast--error {
  background-color: #f2dede;
  color: #a94442;
}

.toast--warning {
  background-color: #fcf8e3;
  color: #8a6d3b;
}

.toast-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button {
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}
</style>
