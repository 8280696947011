<template>
  <div class="h-full flex">
    <div class="border-r w-52 hg-secondary-border px-3 hg-secondary-bg">
      <div class="border-b mb-3 mt-1 pb-2 px-1">
        <div class="cursor-pointer flex font-medium hg-tertiary-btn my-1 items-center space-x-2 flex">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
            </svg>
          </div>
          <div>
            Search
          </div>
        </div>
        <div class="cursor-pointer flex font-medium hg-tertiary-btn my-1 items-center space-x-2">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
            </svg>

          </div>
          <div>
            Customers
          </div>
        </div>
        <div class="cursor-pointer flex font-medium hg-tertiary-btn my-1 items-center space-x-2">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
            </svg>
          </div>
          <div>
            Reports
          </div>
        </div>
      </div>
      <div v-for="product in productStore.products" :key="product.id">
        <div class="ml-2 text-gray-500">{{ product.name }}</div>
        <div class="cursor-pointer flex font-medium hg-tertiary-btn my-1 items-center space-x-2" @click="() => productMenuLink('inbox', product.id)">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H6.911a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661Z" />
            </svg>
          </div>
          <div>
            Inbox
          </div>
        </div>
        <div class="cursor-pointer flex font-medium hg-tertiary-btn my-1 items-center space-x-2" @click="() => productMenuLink('chatbot', product.id)">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
            </svg>
          </div>
          <div>
            Chatbot
          </div>
        </div>
        <div class="cursor-pointer flex font-medium hg-tertiary-btn my-1 items-center space-x-2" @click="() => productMenuLink('docs', product.id)">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
            </svg>

          </div>
          <div>
            Docs
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1">
      <router-view />
    </div>
    <Toast />
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useProductStore } from '/entrypoints/stores/productStore.js';
import Toast from '/entrypoints/components/shared/Toast.vue';

const router = useRouter();
const productStore = useProductStore();


const productMenuLink = (route, id) => {
  switch (route) {
    case 'inbox':
      router.push(`/inbox/${id}`);
      break;
    case 'chatbot':
      router.push(`/products/${id}/chatbot`);
      break;
    case 'docs':
      router.push(`/docs/new`);
      break;
  }
};
</script>

<style lang="scss">
/* Basic editor styles */
.tiptap {
 > * + * {
   margin-top: 0.75em;
 }

 code {
   background-color: rgba(#616161, 0.1);
   color: #616161;
 }
}

.content {
 padding: 1rem 0 0;

 h3 {
   margin: 1rem 0 0.5rem;
 }

 pre {
   border-radius: 5px;
   color: #333;
 }

 code {
   display: block;
   white-space: pre-wrap;
   font-size: 0.8rem;
   padding: 0.75rem 1rem;
   background-color:#e9ecef;
   color: #495057;
 }
}

ul {
  max-height: calc(100vh - 180px);
  overflow: scroll !important;
}
</style>
